import jwtDecode, { JwtPayload } from 'jwt-decode';

type CustomJwtPayload = JwtPayload & {
  sub: string;
  email: string;
  applicationId: string;
  roles: Array<String>;
};

const parseJwt = (token: string) => jwtDecode<CustomJwtPayload>(token);

export const isExpired = (token: string) => {
  const { exp } = parseJwt(token);
  if (Date.now() <= exp! * 1000) {
    return false;
  }
  return true;
};

export default parseJwt;
