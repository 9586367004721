const ModalStatus = {
  Closed: 'closed',
  Upgrade: 'upgrade',
  Manage: 'manage',
  Cancel: 'cancel',
  Edit: 'edit',
  Complete: 'complete',
};

export default ModalStatus;
