import {
  useState,
  useEffect,
  useContext,
  ReactNode,
  ReactElement,
  Dispatch,
  SetStateAction,
  createContext,
} from 'react';
import Router from 'next/router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import Config from '../config/Config';
import User from '../interfaces/user';
import parseJwt, { isExpired } from '../utils/jwt';
import { parseDate } from '../utils';
import { birthDateFormat, membershipTypes } from '../const/profile';
import ChargebeeAPIService from '../services/Chargebee';

const chargebeeService: any = ChargebeeAPIService();

type AuthContextProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  user: User | undefined;
  saveUser: Function;
  registerUser: Function;
  changePassword: Function;
  forgotPassword: Function;
  setPassword: Function;
  verifyEmail: Function;
  resendVerification: Function;
  blockUser: Function;
  checkToken: Function;
  hasRole: Function;
};

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  isLoading: true,
  setAuthenticated: () => {},
  token: '',
  setToken: () => {},
  user: undefined,
  saveUser: () => {},
  registerUser: () => {},
  changePassword: () => {},
  forgotPassword: () => {},
  setPassword: () => {},
  verifyEmail: () => {},
  resendVerification: () => {},
  blockUser: () => {},
  checkToken: () => {},
  hasRole: () => {},
});

export const ROLES = {
  basic: 'basic',
  plus: 'plus',
  premium: 'premium',
  calendar: 'calendar',
  simulator: 'simulator',
  education: 'education',
  guides: 'guides',
  retailers: 'retailers',
  raffles: 'raffles',
  collect: 'collect',
  drops: 'drops',
  assist: 'assist',
  slack: 'slack',
  upgradable: 'upgradable',
};

export function createUser(token: string, user: any): User {
  const parsedJwt = parseJwt(token);
  const userCopy = {
    data: {},
    ...parsedJwt,
    ...user,
  };
  return {
    ...userCopy,
    firstName: userCopy.firstName || userCopy.data.first_name?.toString() || '',
    lastName: userCopy.lastName || userCopy.data.last_name?.toString() || '',
    displayName: userCopy.uniqueUsername || userCopy.username || userCopy.firstName || '',
    birthDate: parseDate(
      userCopy.birthDate || userCopy.data.mepr_birthday?.toString() || '',
      birthDateFormat,
      null
    ),
    locationCountry:
      userCopy.locationCountry || userCopy.data['mepr-address-country']?.toString() || '',
    locationState: userCopy.locationState || userCopy.data['mepr-address-state']?.toString() || '',
    locationCity: userCopy.locationCity || userCopy.data['mepr-address-city']?.toString() || '',
    gender: userCopy.gender || userCopy.data.gender?.toString() || '',
    sneakerSize:
      parseFloat(userCopy.sneakerSize || userCopy.data.mepr_sneaker_size?.toString()) || null,
    sneakerSizeType: userCopy.sneakerSizeType || userCopy.data.sneakerSizeType?.toString() || '',
    instagram: userCopy.instagram || userCopy.data.mepr_instagram_user_name?.toString() || '',
    twitter: userCopy.twitter || userCopy.data.mepr_twitter_user_name?.toString() || '',
    joinReason: userCopy.joinReason || userCopy.data.joinReason?.toString() || '',
    brands: userCopy.brands || userCopy.data.brands || [],
    basicWizardStep: parseInt(userCopy.data.basicWizardStep?.toString() || '0', 10),
    registeredPlan: userCopy.registeredPlan || userCopy.data.price?.toString() || '',
    communityCode: userCopy.communityCode || userCopy.data.communityCode?.toString() || '',
  };
}

const userToFA = (userData: User) => ({
  ...userData,
  data: {
    'mepr-address-country': userData.locationCountry,
    'mepr-address-state': userData.locationState,
    'mepr-address-city': userData.locationCity,
    mepr_sneaker_size: userData.sneakerSize,
    sneakerSizeType: userData.sneakerSizeType,
    mepr_instagram_user_name: userData.instagram,
    mepr_twitter_user_name: userData.twitter,
    first_name: userData.firstName,
    last_name: userData.lastName,
    mepr_birthday: userData.birthDate,
    basicWizardStep: userData.basicWizardStep,
    joinReason: userData.joinReason,
    gender: userData.gender,
    brands: userData.brands,
    price: userData.registeredPlan,
    communityCode: userData.communityCode,
  },
});

export const AuthProvider = ({
  children,
  initToken,
  initUser,
}: {
  children: ReactNode;
  initToken: string;
  initUser?: User;
}): ReactElement => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>(initToken);
  const [user, setUser] = useState<User | undefined>(initUser);
  const [, setCookie] = useCookies(['access_token']);
  const handleError = (error: any) => {
    setLoading(false);
    return {
      data: error?.response?.data,
      ...error.toJSON(),
    };
  };

  const getUser = (_token = token) =>
    axios
      .get('/api/v1/auth/user', {
        // purposely not getting "directly" from FA
        headers: {
          authorization: `Bearer ${_token}`,
        },
        params: {
          applicationId: Config.FA_CLIENT_ID,
        },
      })
      .then(async (result) => {
        const updatedUser = createUser(_token, result.data.user);

        // updatedUser.chargebeeId ='AzqebYTpgH4hh28z3';  // remove later
        if (updatedUser.chargebeeId) {
          updatedUser.subscription = await chargebeeService.subscription(updatedUser);
        }
        console.log('updatedUser', updatedUser);
        setUser(updatedUser);
        setAuthenticated(true);
        setLoading(false);
        return updatedUser;
      });

  const checkToken = (forceRefresh = false) => {
    if (forceRefresh || isExpired(token)) {
      axios
        .post('/next-api/fa/jwt/refresh')
        .then((result) => {
          setAuthenticated(true);
          setToken(result.data.token);
          setCookie('access_token', result.data.token);
          return getUser(result.data.token); // refresh user w/ new token
        })
        .catch(() => {
          setLoading(false);
          toast.error('Your session has expired.');
          setTimeout(() => {
            Router.push('/logout');
          }, 5000);
        });
    } else {
      setTimeout(checkToken, 5000);
    }
  };

  const saveUser = (userData: User) =>
    axios
      .put('/next-api/fa/user/@uid', {
        // use put to replace array values in data
        user: userToFA({
          ...user,
          ...userData,
        }),
      })
      .then((result) => {
        // return getUser();
        const updatedUser = {
          ...user,
          ...userData,
          ...createUser(token, result.data.user),
        };
        setUser(updatedUser);
        return updatedUser;
      })
      .catch(handleError);

  const registerUser = (userData: User) =>
    axios
      .post('/api/v1/auth/register', userToFA(userData))
      .then((result) => result.data)
      .catch(handleError);

  const changePassword = (data: any) =>
    axios.post('/next-api/fa/user/change-password', data).catch(handleError);

  const forgotPassword = (email: string) =>
    axios
      .post(`/next-api/fa/user/forgot-password`, {
        loginId: email,
        sendForgotPasswordEmail: true,
      })
      .catch(handleError);

  const setPassword = (data: any) =>
    axios
      .post('/api/v1/auth/set-password', data, {
        params: {
          applicationId: Config.FA_CLIENT_ID,
        },
      })
      .then((result) => result.data)
      .catch(handleError);

  const verifyEmail = (data: any) =>
    axios
      .post('/api/v1/auth/verify', {
        ...data,
        oneTimeCode: data.oneTimeCode.toUpperCase(),
      })
      .then((result) => result.data)
      .catch(handleError);

  const resendVerification = (email: string) =>
    axios
      .put('/api/v1/auth/verify', { email })
      .then((result) => result.data)
      .catch(handleError);

  const blockUser = () =>
    axios
      .delete('/next-api/fa/user/@uid')
      .then(() => true)
      .catch(handleError);

  const hasRole = (role: string) => {
    const parsedJwt = token && parseJwt(token);

    if (role === ROLES.collect && user?.locationCountry === 'GB') {
      // HACK: international users never have access to collect.
      return false;
    }

    return parsedJwt && parsedJwt?.roles?.includes(role);
  };

  useEffect(() => {
    const checkUser = () => {
      const parsedJwt = parseJwt(token);
      setLoading(true);
      if (parsedJwt.applicationId === Config.FA_CLIENT_ID) {
        if (user?.id && user.id === parsedJwt.sub) {
          setAuthenticated(true);
          setLoading(false);
        } else {
          getUser();
        }
      } else {
        // unrecognized token
        setAuthenticated(false);
        setLoading(false);
      }
    };

    if (token && !isExpired(token)) {
      checkUser();
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user?.id]);

  useEffect(() => {
    if (token && isAuthenticated) {
      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setAuthenticated,
        token,
        setToken,
        user,
        saveUser,
        registerUser,
        changePassword,
        forgotPassword,
        setPassword,
        verifyEmail,
        resendVerification,
        blockUser,
        checkToken,
        hasRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.defaultProps = {
  initUser: null,
};

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function useUser(): User | undefined {
  const { user } = useAuth();

  return user;
}

export function useIsPremium(): boolean {
  const { user } = useAuth();

  return user?.membershipType === membershipTypes.premium;
}

export function useIsPlus(): boolean {
  const { user } = useAuth();

  return user?.membershipType === membershipTypes.plus;
}

export function useIsIapPlus(): boolean {
  const { user } = useAuth();

  return useIsPlus() && !user?.chargebeeId;
}

export function useIsBasic(): boolean {
  const { user } = useAuth();

  return user?.membershipType === membershipTypes.basic;
}

export function getSsoUrl(logout = false): string {
  const ssoUrl = new URL(Config.SSO_URL);
  ssoUrl.pathname = !logout ? '/oauth2/authorize' : '/oauth2/logout';
  ssoUrl.searchParams.set('client_id', Config.FA_CLIENT_ID);
  if (!logout) {
    ssoUrl.searchParams.set('scope', 'offline_access');
    ssoUrl.searchParams.set('response_type', 'code');
    ssoUrl.searchParams.set('redirect_uri', `${Config.APP_URL}/login`);
  }
  return ssoUrl.toString();
}
