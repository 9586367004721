import {
  useState,
  useEffect,
  useContext,
  ReactNode,
  ReactElement,
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
} from 'react';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { AnalyticsBrowser, Analytics } from '@segment/analytics-next';
import { useUser } from './Auth';
import Config from '../config/Config';
import ModalStatus from '../const/modal';
import ChargebeeAPIService from '../services/Chargebee';
import { isTesting } from '../utils';
import Plan, { Price } from '../interfaces/plan';

const chargebeeService: any = ChargebeeAPIService();

type AppContextProps = {
  globalModalStatus: string;
  setGlobalModalStatus: Dispatch<SetStateAction<string>>;
  plans: Plan[];
  prices: Price[];
  userPrice?: Price | null;
};

const AppContext = createContext<AppContextProps>({
  globalModalStatus: ModalStatus.Closed,
  setGlobalModalStatus: () => {},
  plans: [],
  prices: [],
});

export const AppProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const router = useRouter();
  const user = useUser();
  const [globalModalStatus, setGlobalModalStatus] = useState<string>(ModalStatus.Closed);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [prices, setPrices] = useState<Price[]>([]);
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const trackToSegment = Config.SEGMENT_TO_TRACK.split(',');

  useEffect(() => {
    AnalyticsBrowser.load({ writeKey: Config.SEGMENT_API_KEY }).then(([response]: any) =>
      setAnalytics(response)
    );
    if (!isTesting()) {
      chargebeeService.plans().then((result: any) => {
        setPlans(result?.plans);
        setPrices(result?.prices);
      });
    }
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user?.id,
        userMembership: user?.membershipType,
      },
      dataLayerName: 'user',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (trackToSegment.includes('identity') && analytics?.initialized && user?.id) {
      analytics.identify(user.id, {
        name: user.displayName,
        email: user.email,
        plan: user.membershipType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics?.initialized, user?.id]);

  useEffect(() => {
    if (trackToSegment.includes('click') && analytics?.initialized && window.document) {
      window.document.addEventListener('click', (e: any) => {
        const target = e?.path?.find(
          (el: HTMLElement) =>
            el?.matches && el.matches('a,button,[role="button"],[role="menuitem"]')
        );
        if (target) {
          const data = {
            label: target.title || target.name || target.textContent || target.dataset?.testid,
            url: target.href || target.dataset?.href || undefined,
          };
          analytics.track('Clicked', data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics?.initialized]);

  useEffect(() => {
    if (trackToSegment.includes('page') && analytics?.initialized) {
      analytics.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics?.initialized, router.asPath]);

  const userPrice = useMemo(
    () =>
      user?.subscription?.item?.item_price_id &&
      prices.find((_price) => _price.id === user?.subscription?.item?.item_price_id),
    [user?.subscription, prices]
  );

  return (
    <AppContext.Provider
      value={{
        globalModalStatus,
        setGlobalModalStatus,
        plans,
        prices,
        userPrice,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp(): AppContextProps {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}
