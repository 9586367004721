import moment from 'moment';

export const isTesting = () => process?.env?.NODE_ENV === 'test';

export const parseDate = (date?: string | null, format = '', defaultVal?: Date | null) => {
  let parsedDate = new Date(moment(date).toString());
  if (parsedDate.toString() === 'Invalid Date') {
    if (defaultVal !== null) {
      parsedDate = defaultVal || new Date();
    } else {
      return null;
    }
  }
  return format ? moment(parsedDate).format(format) : parsedDate;
};

export default isTesting;
