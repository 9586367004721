import '@material-tailwind/react/tailwind.css';
import '../styles/globals.css';
import type { AppContext, AppProps } from 'next/app';
import Image from 'next/image';
import Cookies from 'cookies';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from '../providers/Auth';
import { AppProvider } from '../providers/App';
import Config from '../config/Config';
import { FeatureHubProvider } from '../providers/FeatureHub';
import { isExpired } from '../utils/jwt';
import contextClass, { bodyClass } from '../const/toast';

interface CustomAppProps extends AppProps {
  token: string;
}

const tagManagerArgs = {
  gtmId: Config.TAG_MANAGER_CONTAINER_ID,
  dataLayerName: 'user',
};

function MyApp({ Component, pageProps, token }: CustomAppProps) {
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    router.beforePopState((e) => {
      Object.assign(e.options, {
        scroll: false,
      });
      return true;
    });

    if (window.top && window.top !== window) {
      // we're in an iframe
      window.top.location.href = window.location.href;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sentry.ErrorBoundary>
      {/* <Provider store={store}> */}
      <AuthProvider initToken={token}>
        <FeatureHubProvider>
          <AppProvider>
            {typeof window !== 'undefined' && window.top !== window ? (
              <div className="items-center">
                <Image src="/ss_logo.svg" alt="SoleSavy Logo" width={112} height={40} />
              </div>
            ) : (
              <Component {...pageProps} />
            )}
            <ToastContainer
              toastClassName={(className) =>
                `${
                  contextClass[className?.type || 'default']
                } relative flex pl-1 pr-3 py-1 min-h-10 rounded-md mb-2 justify-between overflow-hidden cursor-pointer`
              }
              bodyClassName={(className) =>
                `${bodyClass[className?.type || 'default']} flex text-sm block px-2 py-5`
              }
              theme="colored"
              position={toast.POSITION.TOP_RIGHT}
            />
          </AppProvider>
        </FeatureHubProvider>
      </AuthProvider>
      {/* </Provider> */}
    </Sentry.ErrorBoundary>
  );
}

MyApp.getInitialProps = async ({ ctx: { req, res } }: AppContext) => {
  let token = '';

  if (typeof window === 'undefined' && req && res) {
    try {
      const cookies = new Cookies(req, res);
      if (!cookies?.get('onboarding')) {
        cookies.set('onboarding', '1', { httpOnly: false });
      }
      const jwt = cookies?.get('access_token') || '';
      if (jwt && !isExpired(jwt)) {
        token = jwt;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  return { token };
};

export default MyApp;
