import {
  useState,
  useEffect,
  useContext,
  ReactNode,
  ReactElement,
  Dispatch,
  SetStateAction,
  createContext,
} from 'react';
import {
  ClientContext,
  EdgeFeatureHubConfig,
  Readyness,
  fhLog,
} from 'featurehub-javascript-client-sdk';
import Config from '../config/Config';

type SocialLink = {
  name: string;
  text: string;
  link: string;
};

type SubscriptionSchedule = {
  name: string;
  buttonText: string;
  buttonUrl: string;
  cost: string;
};

type Features = {
  storePassword: string;
  referPromo: string;
  showReleases: boolean;
  socialLinks: Array<SocialLink>;
  subscriptionSchedules: Array<SubscriptionSchedule>;
  communities: Array<any>;
};

type FeatureHubContextProps = {
  isInitialized: boolean;
  setInitialized: Dispatch<SetStateAction<boolean>>;
  features: Features;
  setFeatures: Dispatch<SetStateAction<Features>>;
};

const initialFeatures = {
  storePassword: '',
  referPromo: '',
  showReleases: false,
  socialLinks: [],
  subscriptionSchedules: [],
  communities: [],
};

const FeatureHubContext = createContext<FeatureHubContextProps>({
  isInitialized: false,
  setInitialized: () => {},
  features: initialFeatures,
  setFeatures: () => {},
});

export const FeatureHubProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const [features, setFeatures] = useState<Features>(initialFeatures);

  useEffect(() => {
    let fh: EdgeFeatureHubConfig;
    let fhClient: ClientContext;

    const getFeature = (setState: Dispatch<SetStateAction<any>>) => {
      fh.addReadynessListener((readyness) => {
        if (!isInitialized) {
          if (readyness === Readyness.Ready) {
            setInitialized(true);
            setState({
              storePassword: fhClient.getString('STORE_PWD'),
              referPromo: fhClient.getString('WEB_321'),
              showReleases: fhClient.getFlag('WEB_218'),
              socialLinks: fhClient.getJson('WEB_221'),
              subscriptionSchedules: fhClient.getJson('WEB_552'),
              communities: fhClient.getJson('COMMUNITIES'),
            });
          }
        }
      });
    };

    const initializeFeatureHub = async (): Promise<void> => {
      fhLog.trace = (...args: any[]) => {
        console.log(args);
      };
      fh = new EdgeFeatureHubConfig(Config.FEATUREHUB_URL, Config.FEATUREHUB_API_KEY);
      fhClient = await fh.newContext().build();
      console.log('fHClient', fhClient);
      getFeature(setFeatures);
      // console.log('password:', fhClient.getString('STORE_PWD'))
    };

    if (!isInitialized) {
      initializeFeatureHub();
    }
  }, [isInitialized]);
  return (
    <FeatureHubContext.Provider
      value={{
        isInitialized,
        setInitialized,
        features,
        setFeatures,
      }}
    >
      {children}
    </FeatureHubContext.Provider>
  );
};

export function useFeatureHub(): FeatureHubContextProps {
  const context = useContext(FeatureHubContext);
  if (context === undefined) {
    throw new Error('useFeatureHub must be used within an FeatureHubProvider');
  }
  return context;
}
