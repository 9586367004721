import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

import basicPlan, { subscriptionStatus } from '../const/plan';
import Plan, { Price, Subscription } from '../interfaces/plan';
import User from '../interfaces/user';

const client = axios.create({
  baseURL: '/next-api/chargebee',
});

const ChargebeeAPIService = () => ({
  plans: async () =>
    client
      .get('/plans')
      .then((result: any) => {
        const { plans: planMap, error } = result.data;
        const plans: Array<Plan> = Object.values(planMap);
        const prices: Array<Price> = [];

        plans.push(basicPlan);
        plans.forEach((plan: Plan) => {
          plan.prices.forEach((price: Price) => {
            prices.push({
              ...price,
              parent: plan,
            });
          });
        });

        return {
          plans,
          prices,
          error,
        };
      })
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      }),
  hosted: async ({
    user,
    priceId,
    url = window.location.href,
    mode = 'checkout',
    currentSub,
  }: {
    user: User;
    priceId: string;
    url: string;
    mode: string;
    currentSub?: any;
  }) =>
    client
      .post('/hosted', { user, priceId, url, mode, currentSub })
      .then((result: any) => result.data)
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      }),
  estimate: async (priceId: string) =>
    client
      .post('/estimate', { priceId })
      .then((result: any) => result.data)
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      }),
  subscription: async (user: User) =>
    client
      .post(`/subscription`, { id: user.chargebeeId })
      .then((result: any) => {
        const subscription = {
          ...result.data?.subscription,
          communityCode: result.data?.subscription?.cf_group_code,
          currency: result.data?.subscription?.currency_code,
          period: result.data?.subscription?.billing_period_unit,
          renewalDate:
            result.data?.subscription?.next_billing_at &&
            new Date(result.data.subscription.next_billing_at * 1000),
          endDate:
            result.data?.subscription?.current_term_end &&
            new Date(result.data.subscription.current_term_end * 1000),
        };

        subscription.item = subscription?.subscription_items?.find(
          (sub: any) => sub.item_type === 'plan'
        );
        subscription.amount = 0;
        if (subscription.item?.amount) {
          subscription.amount = subscription.item?.amount / 100;
        }

        subscription.isActive =
          subscription.status &&
          [
            subscriptionStatus.active,
            subscriptionStatus.trial,
            subscriptionStatus.nonrenewing,
          ].includes(subscription.status);

        return subscription as Subscription;
      })
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      }),
});

export default ChargebeeAPIService;
