const contextClass = {
  success: 'bg-toastSuccess',
  error: 'bg-toastError',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

export const bodyClass = {
  success: 'text-toastSuccess',
  error: 'text-toastError',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

export default contextClass;
