import { MenuOption } from '../interfaces/dropdown';

const Countries = [
  {
    label: 'Canada',
    value: 'CA',
    options: [
      {
        label: 'Alberta',
        value: 'AB',
      },
      {
        label: 'British Columbia',
        value: 'BC',
      },
      {
        label: 'Manitoba',
        value: 'MB',
      },
      {
        label: 'New Brunswick',
        value: 'NB',
      },
      {
        label: 'Newfoundland',
        value: 'NF',
      },
      {
        label: 'Northwest Territories',
        value: 'NT',
      },
      {
        label: 'Nova Scotia',
        value: 'NS',
      },
      {
        label: 'Nunavut',
        value: 'NU',
      },
      {
        label: 'Ontario',
        value: 'ON',
      },
      {
        label: 'Prince Edward Island',
        value: 'PE',
      },
      {
        label: 'Quebec',
        value: 'QC',
      },
      {
        label: 'Saskatchewan',
        value: 'SK',
      },
      {
        label: 'Yukon Territory',
        value: 'YT',
      },
    ],
  },
  {
    label: 'United Kingdom',
    value: 'GB',
    options: [],
  },
  {
    label: 'United States',
    value: 'US',
    options: [
      {
        label: 'Alaska',
        value: 'AK',
      },
      {
        label: 'Alabama',
        value: 'AL',
      },
      {
        label: 'Arkansas',
        value: 'AR',
      },
      {
        label: 'Arizona',
        value: 'AZ',
      },
      {
        label: 'California',
        value: 'CA',
      },
      {
        label: 'Colorado',
        value: 'CO',
      },
      {
        label: 'Connecticut',
        value: 'CT',
      },
      {
        label: 'District of Columbia',
        value: 'DC',
      },
      {
        label: 'Delaware',
        value: 'DE',
      },
      {
        label: 'Florida',
        value: 'FL',
      },
      {
        label: 'Georgia',
        value: 'GA',
      },
      {
        label: 'Hawaii',
        value: 'HI',
      },
      {
        label: 'Iowa',
        value: 'IA',
      },
      {
        label: 'Idaho',
        value: 'ID',
      },
      {
        label: 'Illinois',
        value: 'IL',
      },
      {
        label: 'Indiana',
        value: 'IN',
      },
      {
        label: 'Kansas',
        value: 'KS',
      },
      {
        label: 'Kentucky',
        value: 'KY',
      },
      {
        label: 'Louisiana',
        value: 'LA',
      },
      {
        label: 'Massachusetts',
        value: 'MA',
      },
      {
        label: 'Maryland',
        value: 'MD',
      },
      {
        label: 'Maine',
        value: 'ME',
      },
      {
        label: 'Michigan',
        value: 'MI',
      },
      {
        label: 'Minnesota',
        value: 'MN',
      },
      {
        label: 'Missouri',
        value: 'MO',
      },
      {
        label: 'Mississippi',
        value: 'MS',
      },
      {
        label: 'Montana',
        value: 'MT',
      },
      {
        label: 'North Carolina',
        value: 'NC',
      },
      {
        label: 'North Dakota',
        value: 'ND',
      },
      {
        label: 'Nebraska',
        value: 'NE',
      },
      {
        label: 'New Hampshire',
        value: 'NH',
      },
      {
        label: 'New Jersey',
        value: 'NJ',
      },
      {
        label: 'New Mexico',
        value: 'NM',
      },
      {
        label: 'Nevada',
        value: 'NV',
      },
      {
        label: 'New York',
        value: 'NY',
      },
      {
        label: 'Ohio',
        value: 'OH',
      },
      {
        label: 'Oklahoma',
        value: 'OK',
      },
      {
        label: 'Oregon',
        value: 'OR',
      },
      {
        label: 'Pennsylvania',
        value: 'PA',
      },
      {
        label: 'Puerto Rico',
        value: 'PR',
      },
      {
        label: 'Rhode Island',
        value: 'RI',
      },
      {
        label: 'South Carolina',
        value: 'SC',
      },
      {
        label: 'South Dakota',
        value: 'SD',
      },
      {
        label: 'Tennessee',
        value: 'TN',
      },
      {
        label: 'Texas',
        value: 'TX',
      },
      {
        label: 'Utah',
        value: 'UT',
      },
      {
        label: 'Virginia',
        value: 'VA',
      },
      {
        label: 'Vermont',
        value: 'VT',
      },
      {
        label: 'Washington',
        value: 'WA',
      },
      {
        label: 'Wisconsin',
        value: 'WI',
      },
      {
        label: 'West Virginia',
        value: 'WV',
      },
      {
        label: 'Wyoming',
        value: 'WY',
      },
    ],
  },
] as Array<MenuOption>;
export default Countries;

export const OtherCountry = {
  label: 'Other',
  value: 'OTHER',
} as MenuOption;

export const SizeTypes = [
  {
    label: 'US',
    value: 'us',
  },
  {
    label: 'UK',
    value: 'uk',
  },
  {
    label: 'EU',
    value: 'eu',
  },
] as Array<MenuOption>;

export const SneakerSizes = [
  {
    value: 3.5,
    'us.m': "3.5 Men's",
    'us.w': "5 Women's",
    uk: '3',
    eu: '35.5',
  },
  {
    value: 4,
    'us.m': "4 Men's",
    'us.w': "5.5 Women's",
    uk: '3.5',
    eu: '36',
  },
  {
    value: 4.5,
    'us.m': "4.5 Men's",
    'us.w': "6 Women's",
    uk: '4',
    eu: '36.5',
  },
  {
    value: 5,
    'us.m': "5 Men's",
    'us.w': "6.5 Women's",
    uk: '4.5',
    eu: '37.5',
  },
  {
    value: 5.5,
    'us.m': "5.5 Men's",
    'us.w': "7 Women's",
    uk: '5',
    eu: '38',
  },
  {
    value: 6,
    'us.m': "6 Men's",
    'us.w': "7.5 Women's",
    uk: '5.5',
    eu: '38.5',
  },
  {
    value: 6.5,
    'us.m': "6.5 Men's",
    'us.w': "8 Women's",
    uk: '6',
    eu: '39',
  },
  {
    value: 7,
    'us.m': "7 Men's",
    'us.w': "8.5 Women's",
    uk: '6',
    eu: '40',
  },
  {
    value: 7.5,
    'us.m': "7.5 Men's",
    'us.w': "9 Women's",
    uk: '6.5',
    eu: '40.5',
  },
  {
    value: 8,
    'us.m': "8 Men's",
    'us.w': "9.5 Women's",
    uk: '7',
    eu: '41',
  },
  {
    value: 8.5,
    'us.m': "8.5 Men's",
    'us.w': "10 Women's",
    uk: '7.5',
    eu: '42',
  },
  {
    value: 9,
    'us.m': "9 Men's",
    'us.w': "10.5 Women's",
    uk: '8',
    eu: '42.5',
  },
  {
    value: 9.5,
    'us.m': "9.5 Men's",
    'us.w': "11 Women's",
    uk: '8.5',
    eu: '43',
  },
  {
    value: 10,
    'us.m': "10 Men's",
    'us.w': "11.5 Women's",
    uk: '9',
    eu: '44',
  },
  {
    value: 10.5,
    'us.m': "10.5 Men's",
    'us.w': "12 Women's",
    uk: '9.5',
    eu: '44.5',
  },
  {
    value: 11,
    'us.m': "11 Men's",
    'us.w': "12.5 Women's",
    uk: '10',
    eu: '45',
  },
  {
    value: 11.5,
    'us.m': "11.5 Men's",
    'us.w': "13 Women's",
    uk: '10.5',
    eu: '45.5',
  },
  {
    value: 12,
    'us.m': "12 Men's",
    'us.w': "13.5 Women's",
    uk: '11',
    eu: '46',
  },
  {
    value: 12.5,
    'us.m': "12.5 Men's",
    'us.w': "14 Women's",
    uk: '11.5',
    eu: '47',
  },
  {
    value: 13,
    'us.m': "13 Men's",
    'us.w': "14.5 Women's",
    uk: '12',
    eu: '47.5',
  },
  {
    value: 13.5,
    'us.m': "13.5 Men's",
    'us.w': "15 Women's",
    uk: '12.5',
    eu: '48',
  },
  {
    value: 14,
    'us.m': "14 Men's",
    'us.w': "15.5 Women's",
    uk: '13',
    eu: '48.5',
  },
  {
    value: 14.5,
    'us.m': "14.5 Men's",
    'us.w': "16 Women's",
    uk: '13.5',
    eu: '49',
  },
  {
    value: 15,
    'us.m': "15 Men's",
    'us.w': "16.5 Women's",
    uk: '14',
    eu: '49.5',
  },
  {
    value: 15.5,
    'us.m': "15.5 Men's",
    'us.w': "17 Women's",
    uk: '14.5',
    eu: '50',
  },
  {
    value: 16,
    'us.m': "16 Men's",
    'us.w': "17.5 Women's",
    uk: '15',
    eu: '50.5',
  },
  {
    value: 16.5,
    'us.m': "16.5 Men's",
    'us.w': "18 Women's",
    uk: '15.5',
    eu: '51',
  },
  {
    value: 17,
    'us.m': "17 Men's",
    'us.w': "18.5 Women's",
    uk: '16',
    eu: '51.5',
  },
  {
    value: 17.5,
    'us.m': "17.5 Men's",
    'us.w': "19 Women's",
    uk: '16.5',
    eu: '52',
  },
  {
    value: 18,
    'us.m': "18 Men's",
    'us.w': "19.5 Women's",
    uk: '17',
    eu: '52.5',
  },
  {
    value: 18.5,
    'us.m': "18.5 Men's",
    'us.w': "20 Women's",
    uk: '17.5',
    eu: '53',
  },
  {
    value: 19,
    'us.m': "19 Men's",
    'us.w': "20.5 Women's",
    uk: '18',
    eu: '53.5',
  },
  {
    value: 19.5,
    'us.m': "19.5 Men's",
    'us.w': "21 Women's",
    uk: '18.5',
    eu: '54',
  },
  {
    value: 20,
    'us.m': "20 Men's",
    'us.w': "21.5 Women's",
    uk: '19',
    eu: '54.5',
  },
  {
    value: 20.5,
    'us.m': "20.5 Men's",
    'us.w': "22 Women's",
    uk: '19.5',
    eu: '55',
  },
  {
    value: 21,
    'us.m': "21 Men's",
    'us.w': "22.5 Women's",
    uk: '20',
    eu: '55.5',
  },
  {
    value: 21.5,
    'us.m': "21.5 Men's",
    'us.w': "23 Women's",
    uk: '20.5',
    eu: '56',
  },
];

export const sneakerSizesByType = (type: 'us.m' | 'us.w' | 'us' | 'uk' | 'eu' | string) =>
  SneakerSizes.map((size: any) => ({
    value: size.value,
    label: type === 'us' ? `${size['us.m']} / ${size['us.w']}` : size[type],
  }));

export const SupportedSneakerSizeMax = 15;

export const Genders = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
] as Array<MenuOption>;

export const inputProps = (props = {}) => ({
  type: 'text',
  autoComplete: 'on',
  color: 'gray',
  outline: true,
  ...props,
});

export const inputRules = ({
  min = 2,
  max = 50,
  required = true,
  email = false,
}: {
  min?: number;
  max?: number;
  required?: boolean | string;
  email?: boolean;
}) => {
  const rules = {
    required,
  } as any;
  if (Number.isInteger(min)) {
    rules.minLength = {
      value: min,
      message: `Minimum of ${min} characters required`,
    };
  }
  if (Number.isInteger(max)) {
    rules.maxLength = {
      value: max,
      message: `Maximum of ${max} characters allowed`,
    };
  }
  rules.validate = (val: string) => {
    if (val.trim().length < val.length) {
      return `No leading/trailing space(s)`;
    }
    if (
      email &&
      !val.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return 'Invalid email address.';
    }
    return true;
  };
  return rules;
};

export const membershipTypes = {
  basic: 'basic',
  plus: 'plus',
  premium: 'premium',
};

export const joinReasons = [
  {
    label: 'To grow my personal sneaker collection',
    value: 'personal',
  },
  {
    label: 'To build community with like-minded sneakerheads',
    value: 'community',
  },
  {
    label: 'To resell sneakers for profit',
    value: 'reseller',
  },
];

export const stepCap = 5;

export const birthDateFormat = 'YYYY-MM-DD';

export const VerificationCodeLength = 6;

export const MinAge = 18;
