import * as Sentry from '@sentry/nextjs';
import Config from './config/Config';

Sentry.init({
  dsn: Config.SENTRY_DSN,
  environment: Config.ENV,
  beforeSend(event) {
    if (event.environment === 'development') {
      console.log(event.exception);
      return null;
    }
    return event;
  },
});
